import React from 'react'
import '../WhyChoseUs/ChoseUs.css'
import img1 from '../../assets/image1.png'
import img2 from '../../assets/image2.png'
import img3 from '../../assets/image3.png'
import img4 from '../../assets/image4.png'
import tick from '../../assets/tick.png'
import nike from '../../assets/nike.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'

const ChoseUs = () => {
  return (
   <React.Fragment>
    <div className="Chose">
      <div className="chose-left">
        <div className="chose-img">
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
        </div>
      </div>
      <div className="chose-right">
        <h3>SOME REASONS</h3>
        <div className="reason-haeding">
        <span className="stroke-text">WHY </span>
        <span>CHOOSE US?</span>
        </div>
        <ul className="lists">
          <li className="list"><img src={tick} alt="" />OVER 140+ EXPERT COACHS</li>
          <li className="list"><img src={tick} alt="" />TRAIN SMARTER AND FASTER THAN BEFORE</li>
          <li className="list"><img src={tick} alt="" />1 FREE PROGRAM FOR NEW MEMBER</li>
          <li className="list"><img src={tick} alt="" />RELIABLE PARTNERS</li>
        </ul>
        <span>OUR PARTNERS</span>
        <div className="partners">
          <img src={nike} alt="" />
          <img src={nb} alt="" />
          <img src={adidas} alt="" />
        </div>
      </div>
    </div>
   </React.Fragment>
  )
}

export default ChoseUs
