import React from 'react'
import Header from '../Header/Header'
import heart from '../../assets/heart.png'
import heroimg from '../../assets/hero_image.png'
import heroback from '../../assets/hero_image_back.png'
import calories from '../../assets/calories.png'

import CountUp from 'react-countup';
// import NumberCounter from 'number-Counter'
import { motion } from 'framer-motion' 
import './Hero.css'

const Hero = () => {
  const transition = {type: 'spring', duration : 3}

  // Mobile Navigation 
  // const mobile = window.innerWidth<=786 ? ture :false;

  return (
    <React.Fragment>
    <div className='Hero'>
      <div className="blur hero-blur"></div>
      <div className="hero-left">
        <Header/>
        {/* Bar */}
        <div className="bar">
          <span className="bar-text">The best fitness club in the town</span>
        <motion.div
          initial={{left : '210px'}}
          whileInView={{left : '8px'}}
          transition= {{...transition, type: 'tween'}}
        ></motion.div>
        </div>
        {/* Hero Main Text */}
        <div className="main-text">
          <div className="main-text-1">
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div className="main-text-2">
            <span>Ideal body</span>
   
          </div>
          <div className="hero-dec">
              <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
            </div>
        </div>
        <div className="figures">
          <div className="members">
            <span className='numbers'> +
              <CountUp  start={120} end={150} duration={2}/>
            </span>
            <span className='figure-text'>Expert Cocches</span>
          </div>
          <div className="members">
          <span className='numbers'>+
          <CountUp  start={900} end={978} duration={2}/>
          </span>
            <span className='figure-text'>Memeber Joined</span>
          </div>
          <div className="members">
          <span className='numbers'>+
          <CountUp  start={30} end={50} duration={2}/>

          </span>
            <span className='figure-text'>Fitnes Program</span>
          </div>
        </div>
        <div className="hero-btns">
          <button className='btn'>Get Started</button>
          <button className='btn'>Learn More</button>
        </div>
      </div>

      {/* Right Side */}
      <div className="hero-right">
        <button className='header-btn'>Join Now</button>

        <motion.div
         initial={{right : "-1rem"}}
         whileInView={{right : "4rem"}}
         transition= {transition}
         className="heart">
          <img src={heart} alt="heart img" />
          <span className='heart-rate'>Heart Rate</span>
          <span className="heart-number">116 bpm</span>
        </motion.div>
        <div className="hero-img">
          <img src={heroimg} alt="" className='hero-image'/>
          <motion.img 
          initial={{right : "11rem"}}
         whileInView={{right: "20rem"}}
         transition={transition}
           src={heroback} alt="" className='hero-back'/>
        </div>
        <motion.div
         initial={{right : '37rem'}}
         whileInView={{right : '28rem'}}
         transition= {{...transition, type: 'tween'}}
         className="calories">
          <img src={calories} alt="" />
          <div className='cal-text'>
            <p>Calories burned</p>
            <p>220 kcal</p>
          </div>
          
        </motion.div>
      </div>
    </div>
    </React.Fragment>
  )
}

export default Hero
