import React,{useRef} from "react";
import "../JoinUs/JoinUs.css";
import emailjs from '@emailjs/browser';

const JoinUs = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_l6dysfh', 'template_ocw6jtk', form.current, 'p2CDiBvrSmsosvBLW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <React.Fragment>
      <div className="join-us">
        <div className="left-j">
          <hr />
          <div>
            <span className="stroke-text">READY TO </span>
            <span>Level Up</span>
          </div>
          <div>
            <span>Your Body </span>
            <span className="stroke-text">With Us</span>
          </div>
        </div>
        <div className="right-j">
          <div className="">
            <form ref={form} className="input" onSubmit={sendEmail}>
              <input
                type="email"
                name="user_email"
                id=""
                placeholder="Enter Your Email Adress Here"
              />
              <button className="btn">Join now</button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JoinUs;
