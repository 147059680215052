import React from 'react'
import './Programs.css'
import {programsData} from '../../data/programsData'
import ProgramCard from './ProgramCard'

const Programs = (props) => {
  return (
    <React.Fragment>
    <div className='programs'>
      <div className="programs-header">
        <span className="stroke-text">Explore Our</span>
        <span>Programs</span>
        <span className="stroke-text">To Shape You</span>
      </div>
    
      <div className="Program-cetagory">
      {programsData.map((expense) =>(
                 <ProgramCard
                 heading={expense.heading}
                 image ={expense.image}
                 details = {expense.details}
               />
      ))}
      </div>
    </div>
    </React.Fragment>
  )
}

export default Programs
