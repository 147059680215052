import React from 'react'
import './ProgramCard.css'
import arrow from '../../assets/rightArrow.png'

const ProgramCard = (props) => {
  return (
   <React.Fragment>
    <div className="program-card">
      {/* <img src={arrow} alt="Program card img" /> */}
      {props.image}
      <span className="card-heading"> {props.heading} </span>
      <span className='card-detail'> {props.details} </span>
      <div className="join-now">
        <span>Join Now</span>
        <img src={arrow} alt="right arrow" />
      </div>
    </div>

   </React.Fragment>
  )
}

export default ProgramCard
