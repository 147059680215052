import React from 'react'
import '../Footer/Footer.css'
import github from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import logo from '../../assets/logo.png'

const Footer = () => {
  return (
   <React.Fragment>
    <div className="footers">
      <hr />
      <div className="footer">
        <div className="logos">
          <img src={github} alt="" />
          <img src={instagram} alt="" />
          <img src={linkedin} alt="" />
        </div>
        <div className="footer-icon">
          <img src={logo} alt="" />
        </div>
        <div className="blur footer-blur-1"></div>
        <div className="blur footer-blur-2"></div>
      </div>
    </div>
   </React.Fragment>
  )
}

export default Footer
