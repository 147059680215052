import './App.css';
import Hero from './components/Hero-section/Hero';
import Programs from './components/Programs/Programs';
import ChoseUs from './components/WhyChoseUs/ChoseUs';
import Plans from './components/plans/plans';
import Testmonials from './components/Testmonials/Testmonials';
import JoinUs from './components/JoinUs/JoinUs';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
          <Hero/>
          <Programs/>
          <ChoseUs/>
          <Plans/>
          <Testmonials/>
          <JoinUs/>
          <Footer/>
    </div>
  );
}

export default App;
