import React from 'react'
import './Header.css'
import logo from '../../assets/logo.png'

const Header = () => {
  return (
    <React.Fragment>
    <div className='header'>
      <img src={logo} alt="logo img" className='logo'/>
      <nav className='nav'>
        <ul className="header-ul">
          <li className="header-list"><a href="#">Home</a></li>
          <li className="header-list"><a href="">Programs</a></li>
          <li className="header-list"><a href="">Why us</a></li>
          <li className="header-list"><a href="">Plans</a></li>
          <li className="header-list"><a href="">Testmonials</a></li>
        </ul>
        
      </nav>
    </div>
    </React.Fragment>
  )
}

export default Header
