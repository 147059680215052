import React from 'react'
import '../plans/Plan.css'
import {plansData} from '../../data/plansData'
import tick from '../../assets/whiteTick.png'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Plans = () => {
  return (
    <React.Fragment>
      <div className="plans">
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>
        <div className="plans-haeding">
          <span className="stroke-text">Ready to Start</span>
          <span>Your Journey</span>
          <span className="stroke-text">Now WithUs</span>
        </div>

        <div className="plan">
        {plansData.map((planed, i) =>(
          <div className="planed" key={i}>
            <span className='icon'>{planed.icon}</span>
            <span className='name'>{planed.name}</span>
            <span className='price'>$ {planed.price}</span>

            <div className="plans-list">
              {planed.features.map((feature, i) =>(
                          <div className="plan-img">
                            <img src={tick} alt="" />
                            <span key={i}>{feature}</span>
                          </div>
                    ))}
                      </div>
                <div><span className='iconss'>See More Benifits <AiOutlineArrowRight/></span></div>
                <button className='btn'>Join Now</button>
          </div>
      ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Plans
