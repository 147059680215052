import React, { useState } from 'react'
import '../Testmonials/Testmonials.css'
import {testimonialsData} from '../../data/testimonialsData'
import leftImg from '../../assets/leftArrow.png'
import rightImg from '../../assets/rightArrow.png'
import { motion } from 'framer-motion'

const Testmonials = () => {
  const transition = {type: 'spring', duration : 3}

  const [selected, setSelected] = useState(0)
  const Ltestmonial = testimonialsData.length

  return (
    <React.Fragment>
      <div className="testmonials">
        <div className="left">
        <span>Testmonials</span>
        <h2 className='stroke-text'>What They</h2>
        <h2>Say About US</h2>

        <motion.div
        key={selected}
        initial={{opacity : 0 , x : -100}}
        animate={{opacity : 1 , x : 0}}
        exit={{opacity : 0 , x : 100}}
        transition= {transition}
         className="slider">
          {testimonialsData[selected].review}
        </motion.div>
        <span
         className='names'>
          <span>{testimonialsData[selected].name}</span>
          <span>-</span>
         <span>{testimonialsData[selected].status}</span>
          </span>

        </div>
        <div className="right">
          <motion.div
           initial={{opacity: 0 , x : -100}}
           whileInView={{opacity: 1 , x : 0}}
           transition={{...transition, duration : 3}}
          ></motion.div>
          <motion.div
                initial={{opacity: 0 , x : 100}}
                whileInView={{opacity: 1 , x : 0}}
                transition={{...transition, duration : 3}}
          ></motion.div>
          <motion.img
          key={selected}
          initial={{opacity : 0 , x : 100}}
          animate={{opacity : 1 , x : 0}}
          exit={{opacity : 0 , x : -100}}
          transition= {transition}
           src={testimonialsData[selected].image} alt="" className='right-img'/>

          <div className="arrow">
            <img 
            onClick={()=>
              {
                selected === 0 ? setSelected(Ltestmonial - 1):
                setSelected((prev) => prev - 1);
              }
            } 
            src={leftImg} alt="" />
            <img 
              onClick={()=>
              {
                selected === Ltestmonial - 1 ? setSelected(0) :
                setSelected((prev) => prev + 1)
              }
              }
            src={rightImg} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Testmonials
